import * as Sentry from '@sentry/vue'

import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
    app: { environment },
  } = useRuntimeConfig()

  // If no sentry DSN set, ignore and warn in the console
  if (!sentry.dsn && environment !== 'test') {
    // eslint-disable-next-line no-console
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    enabled: !!sentry.dsn,
    ignoreErrors: [
      /^Page Not Found$/,
    ],
  })

  nuxtApp.provide('sentry', Sentry)
})

declare module '#app' {
  interface NuxtApp {
    $sentry: typeof Sentry
  }
}
