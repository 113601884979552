import revive_payload_client_U5Bg07sJuc from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sXkrfLqTQ2 from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qTtBstozQK from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ze9cqhQUa1 from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rUV7zEtRo8 from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_cj5Vl4rRgy from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_7eHDU6Winp from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@8.57.0_inicdon36khqhzxrmypzdgzmmu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZwNv5Enhct from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@parcel+watcher@2.4.1_@types+node@22.10.7_encoding@0.1.13_graphql-_ge3vc5lg7i23t4tj6w5m4w5hlm/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import sentry_client_shVUlIjFLk from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/sentry.client.ts";
import set_graphql_host_client_hEFjBexpur from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/set-graphql-host.client.ts";
import toasts_client_unuLMHjfa9 from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/toasts.client.ts";
export default [
  revive_payload_client_U5Bg07sJuc,
  unhead_sXkrfLqTQ2,
  router_qTtBstozQK,
  payload_client_ze9cqhQUa1,
  navigation_repaint_client_rUV7zEtRo8,
  chunk_reload_client_cj5Vl4rRgy,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7eHDU6Winp,
  plugin_ZwNv5Enhct,
  sentry_client_shVUlIjFLk,
  set_graphql_host_client_hEFjBexpur,
  toasts_client_unuLMHjfa9
]